import "./index.scss"

import Home from "./home/Home";
import AboutMe from "./aboutme/AboutMe";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";
import Skills from "./skills/Skills";
import Particles from "@tsparticles/react";
import { useMemo } from "react";

export default function MainPage({scroll, particlesLoaded}) {
  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#2B2B2B",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          repulse: {
            distance: 120,
            duration: 0.4,
          },
          grab: {
            distance: 300,
            links : {
              opacity: 0.25
            }
          },
        },
      },
      particles: {
        color: {
          value: "none",
        },
        links: {
          color: "#1A1A1A",
          distance: 300,
          enable: true,
          opacity: 0.15,
          width: 6,
          triangles: {
            enable: true,
            blur: 2
          },
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 3,
          straight: false,
        },
        number: {
          density: {
            enable: false,

          },
          value: 100,
        },
        size: {
          value: 7,
        },
      },
      detectRetina: true,
      
    }),
    []
  );

  return (
    <div className="mainpage">
      <Particles id="tsparticles" options={options} init={particlesLoaded} className="particles"/>
      <section id="home">
        <Home scroll={scroll} particlesLoaded={particlesLoaded}/>
      </section>
      <section id="aboutme">
        <AboutMe scroll={scroll}/>
      </section>
      <section id="projects">
        <Projects scroll={scroll}/>
      </section>
      <section id="skills">
        <Skills scroll={scroll}/>
      </section>
      <section id="contact">
        <Contact scroll={scroll}/>
      </section>
    </div>
  )
}