import { useEffect, useRef, useState } from "react";
import "./index.scss"
import { SlArrowUp, SlArrowDown} from "react-icons/sl";
import zatreImage from "../../../images/projects/zatre.png";
import delawareImage from "../../../images/projects/delaware.png";
import libraryImage from "../../../images/projects/library.jpg";
import metmuseumImage from "../../../images/projects/metmuseum.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import IconGithub from "../../../images/icons/Github";


const projects = [
  {
    title: "Zatre",
    content1: "With the help of three fellow students, we built a Java application for a game called Zatre.",
    content2: "During the development, we worked closely together to design and implement numerous features of the game, such as the user interface and the gaming mechanics. The project was a great learning experience, where everyone had the opportunity to contribute their ideas and skills to implement a fun and engaging game.",
    content3: "The game was developed with my team members: Gunar Callebaut, Senne Dierick and Jarne Willaert. Together we made our first steps in the world of IT and software development.",
    skills: ["Java", "JavaFX", "CSS", "MySQL"],
    url: "https://github.com/Sebastiaan149/Zatre"
  },
  {
    title: "Delaware B2B Portal",
    content1: "As part of our second year project at HOGENT, we were tasked with developing a B2B portal for the company Delaware.",
    content2: "The portal was designed to provide a user-friendly interface for businesses to manage their orders, products and deliveries. With some hard work and dedication, we were able to deliver a fully functional portal that met the requirements of the company.",
    content3: "The project was a great chance to work on a real-world project and gain valuable experience in web development and software engineering, with communication with the client being a key aspect of the project. This was all thanks to the fantastic collaboration with: Yani Degrande, Warre Vandenhoucke, Thomas Odvart and Jente Coppejans.",
    skills: ["React", "Node.js", "SCSS", "MySQL", "Prisma"],
    url: "https://github.com/Sebastiaan149/delaware-b2b-portal"
  },
  {
    title: "Sebastiaan's Library",
    content1: "In my second year at HOGENT, I was also tasked with developing an online library catalog system.",
    content2: "The system was designed to allow users to search for books, view details about them and give them a rating. This was developed using JPA and Spring Boot, with a MySQL database to store the book information. In combination with a REST API, the system was able to provide a user-friendly interface for users to interact with the library catalog.",
    content3: "This allowed me to learn a different way of implementing a web application, without using the traditional JavaScript frameworks.",
    skills: ["Java", "Spring Boot", "JPA", "CSS", "MySQL"],
    url: "https://github.com/Sebastiaan149/library"
  }, 
  {
    title: "Delaware B2B Portal - Desktop",
    content1: "As part of our second year project at HOGENT, we were also tasked with developing a desktop version of the Delaware B2B Portal.",
    content2: "The desktop version was designed to provide a management interface for businesses to manage their orders, customers, transport services and employees. Together with Yani Degrande, Warre Vandenhoucke, Thomas Odvart and Jente Coppejans, we used Java and JavaFX to develop the desktop application, with a MySQL database to store the data. This database was then also used by the web application to provide a consistent data source for both applications.",
    content3: "The project allowed us to work on a realistic use case and further expand our knowledge of software development.",
    skills: ["Java", "JavaFX", "JPA", "CSS", "MySQL"],
    url: "https://github.com/Sebastiaan149/delaware-b2b-desktop"
  },
  {
    title: "Metropolitan Museum of Art - Mobile",
    content1: "For this school project, I was tasked with developing a mobile application for the Android platform.",
    content2: "The result was a native Android application that allowed users to browse the collection of the Metropolitan Museum of Art in New York. The application was developed using Kotlin and Android Studio, while accessing the museum's collection through their public API.",
    content3: "Together with offline functionality, the application provided complete access to the museum's collection straight into your own hands.",
    skills: ["Kotlin", "roomDB"],
    url: "https://github.com/Sebastiaan149/metmuseum-android"
  },
];

export default function Projects( {scroll} ) {
  const [currentProject, setCurrentProject] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
    });
  }, []);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '-80px',
      threshold: 0.5, // 0 to 1; 1 means fully visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    const div = divRef.current;

    return () => {
      if (div) {
        observer.unobserve(div);
      }
    };
  }, []);

  const handleScrollUp = () => {
    scroll("aboutme");
  };

  const handleScrollDown = () => {
    scroll("skills");
  };

  return (
    <div className="projects">
      <SlArrowUp className="up" onClick={handleScrollUp}/>
      <div className="projectsContent">
        <h1 ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateY(${isVisible ? 0 : '-200px'})`, // move from left
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
          }}>PROJECTS</h1>

        <div className="content">
          <div className="projectInfo" ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateY(${isVisible ? 0 : '-200px'})`, // move from left
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
          }}>
            <h3>{projects[currentProject].title}</h3>
            <p>
              {projects[currentProject].content1} <br/>
              {projects[currentProject].content2} <br/>
              {projects[currentProject].content3} 
            </p>
            <div className="skills">
              {projects[currentProject].skills.map((skill, index) => (
                <span key={index} className="skill">
                  {skill}
                </span>
              ))}
            </div> 
            
            <div className="githubLink">
              <a href={projects[currentProject].url} target="_blank" rel="noreferrer">Github </a>
              <IconGithub className="githubIcon"/>
            </div>
          </div>

          <div className="projectsImages" ref={divRef}
            style={{
              opacity: isVisible ? 1 : 0,
              transform: `translateY(${isVisible ? 0 : '100px'})`, // move from left
              transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
            }}>
            {projects.map((project, index) => (
              <div key={index} className="container" onClick={() => setCurrentProject(index)}>
                <img src={index === 0 ? zatreImage : index === 1 ? delawareImage : index === 2 ? libraryImage : index === 3 ? delawareImage : metmuseumImage} alt="project" className="projectImage"/>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SlArrowDown className="down" onClick={handleScrollDown}/>
    </div>
  );
}