import "./index.scss"
import { SlArrowUp, SlArrowDown} from "react-icons/sl";
import 'aos/dist/aos.css';
import 'react-vertical-timeline-component/style.min.css';
import { useEffect, useRef, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function AboutMe({scroll}) {
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
      debounceDelay: 50
    });
  }, []);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '-80px',
      threshold: 0.8, // 0 to 1; 1 means fully visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    const div = divRef.current;

    return () => {
      if (div) {
        observer.unobserve(div);
      }
    };
  }, []);

  const handleScrollUp = () => {
    scroll("home");
  };

  const handleScrollDown = () => {
    scroll("projects");
  };

  
  return (
    <div className="aboutme">
      <SlArrowUp className="up" onClick={handleScrollUp}/>
      <div className="aboutMeContent">
        <h1
          ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateX(${isVisible ? 0 : '-100px'})`, // move from left
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
          }}>
          ABOUT ME
        </h1>

        <div className="content">
          <p ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateX(${isVisible ? 0 : '-100px'})`, // move from left
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
          }}>
          My name is Sebastiaan Delodder, and I am a 22-year-old student currently pursuing a degree in Applied Computer Sciences at HOGENT. With a strong passion for programming languages, I am always eager to explore and master new coding technologies. My interests also extend to the latest technological advancements and artificial intelligence, areas where I see immense potential for innovation and problem-solving. Whether it's diving into a new programming language or working on a challenging project, I am always ready to take on new challenges and expand my knowledge in the field of computer sciences.</p>
          <div ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateX(${isVisible ? 0 : '+100px'})`, // move from left
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
          }}>
            <VerticalTimeline layout="1-column-left" className="timeline">
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                position="right"
                iconStyle={{ background: 'rgb(116 116 116)', color: '#fff' }}
                contentArrowStyle={{ display: 'none' }}
                contentStyle={{backgroundColor: 'transparent', WebkitBoxShadow: 'none', boxShadow: 'none'}}
              >
                <div ref={divRef}
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: `translateX(${isVisible ? 0 : '+100px'})`, // move from left
                  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
                }}>
                  <h3 className="vertical-timeline-element-title">2021-2024</h3>
                  <h4 className="vertical-timeline-element-subtitle">HOGENT</h4>
                  <p className="timeline-text">
                    Applied Computer Sciences -
                  </p>
                  <p>
                    Bachelor's Degree
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                position="right"
                iconStyle={{ background: 'rgb(116 116 116)', color: '#fff' }}
                contentArrowStyle={{ display: 'none' }}
                contentStyle={{backgroundColor: 'transparent', WebkitBoxShadow: 'none', boxShadow: 'none'}}
              >
                <div ref={divRef}
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: `translateX(${isVisible ? 0 : '+100px'})`, // move from left
                  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
                }}>
                  <h3 className="vertical-timeline-element-title">2020-2021</h3>
                  <h4 className="vertical-timeline-element-subtitle">UGent</h4>
                  <p className="timeline-text">
                    Computer Sciences -
                  </p>
                  <p>
                    Bachelor's Degree
                  </p>
                </div>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                position="right"
                iconStyle={{ background: 'rgb(116 116 116)', color: '#fff' }}
                contentArrowStyle={{ display: 'none' }}
                contentStyle={{backgroundColor: 'transparent', WebkitBoxShadow: 'none', boxShadow: 'none'}}
              >
                <div ref={divRef}
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: `translateX(${isVisible ? 0 : '+100px'})`, // move from left
                  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // animate opacity and transforms
                }}>
                  <h3 className="vertical-timeline-element-title">2014-2020</h3>
                  <h4 className="vertical-timeline-element-subtitle">Maricolen Maldegem</h4>
                  <p className="timeline-text">
                    Latin-Mathematics -
                  </p>
                  <p>
                    High School Degree
                  </p>
                </div>
              </VerticalTimelineElement>
           </VerticalTimeline> 
          </div>
        </div>
      </div>
      <SlArrowDown className="down" onClick={handleScrollDown}/>
    </div>
  );
}