import { useState } from "react";
import "./index.scss";
import { SlArrowUp} from "react-icons/sl";

export default function Contact({scroll}) {
  const [isSent, setIsSent] = useState(false);

  const handleScrollUp = () => {
    scroll("skills");
  };

  const submit = () => {
    setIsSent(true);
  }

  return (
    <div className="contact">
      <SlArrowUp className="up" onClick={handleScrollUp}/>
      <div className="contactContent">
        <h1>CONTACT</h1>
        <div className="content">
          <div className="form">
            <div className="fields">
              <div className="left">
                <div className="nameInput">
                  <input type="text" placeholder="Firstname"/>
                  <input type="text" placeholder="Lastname"/>
                </div>

                <input type="email" placeholder="Email"/>
                <input type="tel" placeholder="Phone number"/>
              </div>

              <textarea placeholder="Message"/>
            </div>

            <button type="submit" onClick={() => submit()}>
              Send
            </button>

            <p className="sent">{isSent ? "Message sent!" : ""}</p>
          </div>
        </div>

        <div className="additional">
          <h2>Additional information</h2>
          <div className="info">
            <div className="linkedinLink">
              <a href="/documents/CV - Sebastiaan Delodder.pdf" target="_blank" rel="noreferrer">Download CV </a>
            </div>

            <div className="linkedinLink">
              <a href="https://www.linkedin.com/in/sebastiaan-delodder-103098295/" target="_blank" rel="noreferrer">Linkedin </a>
              <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" fill="#0F0F0F"/>
              <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" fill="#0F0F0F"/>
              <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" fill="#0F0F0F"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" fill="#0F0F0F"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}