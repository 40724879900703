import { useTranslation } from "react-i18next";
import "./index.scss";

function NavItem({ text, scroll, item}) {

  const handleClick = () => {
    scroll(item);
  }
  
  return (
    <div className="navitem" onClick={handleClick}>
      {text}
    </div>
  );
}

export default function Navbar( {scroll} ) {
  const [t] = useTranslation("common");

  return (
    <nav>
      <div className="navitems">
        <NavItem text={t("navbar.home")} scroll={scroll} item = "home"/>
        <NavItem text={t("navbar.about")} scroll={scroll} item = "aboutme"/>
        <NavItem text={t("navbar.projects")} scroll={scroll} item = "projects"/>
        <NavItem text={t("navbar.skills")} scroll={scroll} item = "skills"/>
        <NavItem text={t("navbar.contact")} scroll={scroll} item = "contact"/>
      </div>
    </nav>
  );
}