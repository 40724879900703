import * as React from "react";

function IconBlazor(){
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1000.000000pt" height="1000.000000pt" viewBox="0 0 1000.000000 1000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M9430 9517 c-13 -7 -51 -63 -92 -135 -324 -580 -641 -975 -1013
-1265 -342 -266 -773 -478 -1268 -622 -200 -58 -221 -40 -129 112 224 371 379
803 443 1238 25 175 25 193 -6 207 -30 13 -59 -3 -105 -61 -40 -49 -236 -227
-350 -317 -376 -297 -852 -524 -1320 -628 -310 -68 -310 -68 -1270 -76 -553
-5 -912 -12 -975 -19 -492 -57 -903 -181 -1330 -400 -349 -179 -607 -366 -891
-646 -831 -822 -1230 -1960 -1099 -3135 81 -727 355 -1399 792 -1945 112 -141
337 -371 478 -490 537 -454 1212 -741 1944 -830 398 -48 972 -34 1461 36 1000
142 1886 536 2621 1166 166 143 439 412 439 434 0 11 -3 19 -6 19 -4 0 -59
-33 -123 -73 -1012 -638 -2249 -987 -3498 -987 -228 0 -535 19 -717 45 -1461
205 -2563 1303 -2731 2720 -30 256 -17 644 31 905 104 568 375 1110 770 1542
416 454 993 789 1589 923 262 59 365 69 685 69 320 0 423 -10 685 -69 704
-158 1351 -581 1793 -1171 345 -461 556 -1030 613 -1657 16 -178 7 -474 -19
-595 -120 -551 -486 -933 -962 -1003 -101 -14 -292 -7 -400 16 -266 55 -486
190 -631 385 l-49 67 -108 -106 c-117 -117 -241 -205 -392 -281 -423 -212
-924 -207 -1348 15 -146 76 -250 154 -377 280 -214 212 -353 469 -420 775 -22
101 -31 370 -16 485 45 340 193 638 441 885 219 220 470 356 779 422 89 20
137 21 650 25 485 4 563 2 635 -12 238 -48 423 -221 493 -460 15 -53 18 -135
23 -725 7 -738 5 -710 77 -858 68 -139 193 -234 351 -267 255 -55 463 46 578
280 69 141 86 218 91 435 10 389 -70 743 -252 1116 -129 262 -280 469 -502
684 -316 308 -686 517 -1107 625 -194 50 -318 66 -546 72 -318 8 -556 -26
-850 -123 -163 -54 -437 -187 -575 -279 -487 -325 -841 -801 -1008 -1355 -94
-312 -128 -666 -93 -970 63 -542 326 -1053 748 -1455 208 -198 419 -343 693
-476 445 -217 850 -290 1525 -275 601 13 1047 79 1565 231 1630 481 3011 1685
3689 3217 302 683 444 1328 463 2103 19 809 -128 1513 -445 2119 -60 115 -80
134 -122 113z"/>
<path d="M3468 5154 c-335 -60 -613 -315 -710 -649 -20 -70 -23 -101 -23 -250
1 -159 3 -175 29 -255 56 -169 126 -280 253 -401 312 -298 790 -328 1149 -74
80 57 200 186 247 266 49 83 91 194 111 291 13 64 16 159 16 567 l0 490 -22
15 c-20 14 -83 16 -498 15 -358 -1 -494 -4 -552 -15z"/>
</g>
</svg>

  )
}

export default IconBlazor;