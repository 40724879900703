import { useCallback, useEffect, useRef, useState } from "react";
import "./index.scss"
import {SlArrowDown} from 'react-icons/sl';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Home({scroll, particlesLoaded}) {
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-in-out",
      debounceDelay: 50
    });
  }, []);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '-80px',
      threshold: 0.8, // 0 to 1; 1 means fully visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    const div = divRef.current;

    return () => {
      if (div) {
        observer.unobserve(div);
      }
    };
  }, []);

  const handleScroll = useCallback(() => {
    scroll("aboutme");
  }, [scroll]);

  return (
    <div className="home">
      <div className="homeContent"
          ref={divRef}
          style={{
            opacity: isVisible ? 1 : 0,
            transform: `translateX(${isVisible ? 0 : '-100px'})`, // move from left
            transition: 'opacity 0.4s ease-in-out, transform 0.4s ease-in-out', // animate opacity and transforms
          }}>

        <h1>Welcome!</h1>
        <div className="content">
          <p>
            I'm Sebastiaan Delodder, currently studying Computer Sciences at HoGent University in Ghent,
            Belgium. I have a keen interest in web development, AI and the latest cutting-edge developments
            in IT. Be sure to scroll down and find out more about me!
          </p>
          <img src={require("../../../images/profile.png")} alt="profile"/>
        </div>
      </div>
      
      <SlArrowDown onClick={handleScroll} className="down"/>
    </div>
  );
}