import { useCallback, useEffect, useState } from "react";
import "./App.scss";
import MainPage from "./components/mainPage/MainPage";
import Navbar from "./components/navbar/Navbar";
import { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";

function App() {
  const [init, setInit] = useState(false);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      await loadFull(engine);
      //await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const scroll = useCallback((id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({behavior: "smooth"});
  }, []);

  if (windowWidth < 1500 || windowHeight < 710) {
    return (
      <div className="error">
        <h1>Mobile version coming soon</h1>
        <p>Please use a device with a larger screen as a temporary solution</p>
      </div>
    );
  }

  if (init) {
    return (
      <div className="page">
        <header>
          <Navbar scroll={scroll}/>
        </header>
        <main>
          <MainPage scroll={scroll} particlesLoaded={particlesLoaded}/>
        </main>
      </div>
    );
  }
}

export default App;
